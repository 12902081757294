<template>
  <div>
    <div>
      <v-row dense class="pb-6">
        <div cols="2">
          <v-btn large icon text @click="$router.go(-1)">
            <v-icon color="#00a7e4" medium>mdi-chevron-left</v-icon>
          </v-btn>
        </div>
        <v-col cols="10">
          <span class="subtitulo-header">{{ getLeadName }}</span>
        </v-col>
      </v-row>
    </div>
    <v-row>
      <v-col cols="12" sm="12" md="12" lg="12" xl="12">
        <cards-info-panel
          :infoCards="infoCards"
          :routesConfigNoteCard="routesConfigNoteCard"
          :personalData="personalData"
          :emailRoutesConfig="emailRoutesConfig"
          @getData="getLead"
        >
        </cards-info-panel>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <leadsIndexTab
          :needQuotationTab="needQuotationTab"
          @showedTab="
            (e) => {
              tab = e;
            }
          "
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <leadInfoIndex
          v-if="tab == 1"
          :lead="lead"
          @showModal="dialog = true"
        />
        <QuotationIndex
          v-if="tab == 2 && needQuotationTab"
          :leadID="lead.id"
          :isFromPefai="isFromPefai"
          :wasSeeditToPefai="wasSeeditToPefai"
          :needBeCreateInPefai="needBeCreateInPefai"
          :origenUuidToPefai="origenUuidToPefai"
          :origenIdToPefai="origenIdToPefai"
          @leadCreadoPefai="leadCreadoPefai"
        />
        <div v-if="tab == 3">
          <EmissionIndex v-if="needQuotationTab" />
          <EmisionClienteIndex @openCreatePolizaModal="openCreatePolizaModal = true" :polizasCliente="emisiones" @leadIncomplete="leadIncomplete" v-else />
        </div>
      </v-col>
      <v-col cols="12" v-if="llamadaMasiva || (llamadaManual && campaingsSelected.length > 0)">
        <Embedded
          :leadOrigen="origenLead"
          :lead="lead"
          :campaings="infoCards.campaings"
          @successEmbedded="successEmbedded"
          @getLlamadas="getLlamadas"
          @leadIncomplete="leadIncomplete"
          @openPolizasModal="openCreatePolizaModal = true"
        ></Embedded>
      </v-col>
      <ShowCampaingDetailDialog
        v-if="showCampaingModal"
        :show="showCampaingModal"
        :origenUuid="origenUuid"
        @close="showCampaingModal = false"
      />
      <LeadDetallesmodal
        :dialog="dialog"
        :uuidLead="$route.params.uuid"
        :saveChanges="true"
        @close_dialog="dialog = false"
        @lead_actualizado="getLead"
      />
    </v-row>
    <CreatePolizaModal
      v-if="openCreatePolizaModal"
      :openCreatePolizaModal="openCreatePolizaModal"
      :lead="lead"
      @getPolizas="polizaSuccess"
      @closeModal="openCreatePolizaModal = false"
    ></CreatePolizaModal>
  </div>
</template>

<script>
import {
  getLeadByUuid,
  getCampaigsByLeadUuid,
  getCallsForLead,
  getOrigenLeadByUUID,
  getLeadEmisionesById,
} from "@/services/leads/leads.service";
import leadInfoIndex from "./leadInfo/leadInfoIndex.vue";
import leadsIndexTab from "./leadsIndexTab.vue";
import QuotationIndex from "./quotationInfo/QuotationIndex.vue";
import EmissionIndex from "./emissionInfo/EmissionIndex.vue";
import CardsInfoPanel from "./leadInfo/cardsInfo/CardsInfoPanel.vue";
import moment from "moment";
import Embedded from "@/components/commonComponents/Embedded.vue";
import ShowCampaingDetailDialog from "@/components/commonComponents/dialogs/ShowCampaingDetailDialog.vue";
import LeadDetallesmodal from "@/components/commonComponents/dialogs/LeadDetallesmodal.vue";
import { getCampaingsList } from "@/services/campaings/campaing.service";
import CreatePolizaModal from "@/components/commonComponents/dialogs/CreatePolizaModal.vue";
import EmisionClienteIndex from "@/views/pages/hub/clientes/Details/EmisionesClienteData/EmisionClienteIndex.vue";

export default {
  components: {
    CardsInfoPanel,
    leadInfoIndex,
    leadsIndexTab,
    QuotationIndex,
    Embedded,
    EmissionIndex,
    ShowCampaingDetailDialog,
    LeadDetallesmodal,
    CreatePolizaModal,
    EmisionClienteIndex,
  },

  data() {
    return {
      config: {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      },
      personalData: {
        rol: "",
      },
      stateList: [],
      correosList: [],
      routes: {},
      routesConfig: {
        completeData: true,
        route: "",
        action: "PUT",
      },
      contactData: {},
      routesConfigPassword: {
        route: "",
        action: "POST",
        uuidCambio: "",
      },
      infoCards: {
        notas: [],
        docs: [],
        emails: [],
        campaings: [],
        llamadas: [],
      },
      rol: JSON.parse(localStorage.getItem("roles"))[0].rol,
      routesConfigNoteCard: {
        route: "",
        action: "POST",
      },
      correosDataFormat: {
        correos: [],
        action: "POST",
      },
      routesContactData: {
        routeCommond: "",
        routeCommondAction: "PUT",
        deleteEmails: "/v1/correo-contacto/delete",
        changeEmails: "/v1/correo-contacto/update/", //query param idCorreo para modificar correo
        insertEmails: "/v1/correo-contacto/insert",
      },
      emailRoutesConfig: {
        sendEmailRoute: "",
        action: "",
        optionCorreosList: [],
        destinatario: "",
      },
      screenWidth1: window.innerWidth,
      canEdit: false,
      lead: {
        nombre: "",
        apellidopaterno: "",
        apellidomaterno: "",
        telefono: "",
        correo: "",
        campania_id: "",
        regimen: "",
        rfc: "",
        razon_social: "",
        edad: "",
        sexo: "",
        estado_civil: "",
        fecha_nacimiento: "",
        calle: "",
        num_interior: "",
        num_exterior: "",
        codigo_postal: "",
        colonia: "",
        ciudad: "",
        estado: "",
        pais: "",
        reus: 0,
        status: "",
      },
      tab: 1,
      campaigs: [],
      llamadas: [],
      llamadaMasiva: false,
      origenLeadUuid: "",
      origenLead: {},
      showCampaingModal: "",
      origenUuid: "",
      dialog: false,
      isFromPefai: false,
      wasSeeditToPefai: false,
      needBeCreateInPefai: false,
      origenUuidToPefai: "",
      origenIdToPefai: "",
      openCreatePolizaModal:false,
      emisiones:[],
      llamadaManual: false,
    };
  },
  computed: {
    getLeadName() {
      return (
        (this.lead.nombre ?? "") +
        " " +
        (this.lead.apellidoPaterno ?? "") +
        " " +
        (this.lead.apellidoMaterno ?? "")
      );
    },
    needQuotationTab() {
      if (
        !this.isFromPefai &&
        !this.needBeCreateInPefai &&
        !this.wasSeeditToPefai
      )
        return false;
      else return true;
    },
    campaingsSelected() {
      if (this.infoCards.campaings.length > 0)
        return  this.infoCards.campaings.filter((e) => {
          return e.status != "Finalizado";
        });
      else return [];
    },

   
  },
  methods: {
    async getLead() {
      this.dialog = false;
      if (typeof this.$route.params.uuid != "undefined") {
        this.lead = await getLeadByUuid(this.$route.params.uuid);
        this.emisiones = await getLeadEmisionesById(this.lead.id);
        const campaings = await getCampaigsByLeadUuid(
            this.$route.params.uuid
          );
        const origenLeadUuid = this.$route.query.origenLeadUuid;
        this.infoCards.campaings = origenLeadUuid 
          ? campaings.filter(camp => camp.uuid === origenLeadUuid) 
          : campaings;

        this.infoCards.campaings.sort(
          (a, b) => new Date(b.fecha_lead) - new Date(a.fecha_lead)
        );
        this.calculateIfleadNeedToBeCreateInPefai();
      }
      this.routesConfigNoteCard.route =
        "/v1/leadSegurify/documento/upload/" + this.lead.id;
      if (this.lead.documentos.length > 0)
        this.formatNotasDocumentos(this.lead.documentos);
    },
    formatNotasDocumentos(docs) {
      this.infoCards.notas = [];
      this.infoCards.docs = [];
      docs.map((e) => {
        if (e.tipoRegistro == "Nota" || e.tipoRegistro == "nota")
          this.infoCards.notas.push({
            formatData:
              moment
                .utc(e.fecha)
                .subtract(6, "hours")
                .format("DD/MM/YYYY") +
              " " +
              e.detalle.slice(0, 10) +
              " ... " +
              moment
                .utc(e.fecha)
                .subtract(6, "hours")
                .format("HH:mm"),
            dateFormat: moment
              .utc(e.fecha)
              .subtract(6, "hours")
              .format("DD/MM/YYYY HH:mm"),
            url: e.url_bucket,
            ...e,
          });
        else {
          this.infoCards.docs.push({
            dateFormat: moment
              .utc(e.fecha)
              .subtract(6, "hours")
              .format("DD/MM/YYYY HH:mm"),
            onlyDate: moment
              .utc(e.fecha)
              .subtract(6, "hours")
              .format("DD/MM/YYYY"),
            ...e,
          });
        }
      });
    },
    async getLlamadas() {
      this.infoCards.llamadas = await getCallsForLead(this.$route.params.uuid);
    },
    async getOrigenLead() {
      this.origenLead = await getOrigenLeadByUUID(this.origenLeadUuid);
      this.llamadaMasiva = this.$route.query.llamadaMasiva
        ? this.$route.query.llamadaMasiva === "true"
          ? true
          : false
        : false;
    },
    successEmbedded() {
      if (this.$route.query.llamadaMasiva === "true") {
        this.origenUuid = this.$route.query.origenLeadUuid;
        this.showCampaingModal = true;
      }
    },
    async calculateIfleadNeedToBeCreateInPefai() {
      //Primero tengo que checar la llamada masiva
      //True
      //agarro el param del origen uuid y le saco el medio, si el meido no es de pefai escondo todo lo de cotizaciones y
      //le muestro lo polizas poliza del CRM
      //Si es true y es de pefai lo mando a estos casos de abajo

      //False
      //agarro el caso 3 para checar si alguno de sus origenes lead va para pefai si no le escondo todo cotizaciones
      //si alguno va entonces en las validaciones ya hechas

      //Checo si es un lead mandado directamente de pefai o que ya se mando
      this.infoCards.campaings.some((e) => {
        var request_json = JSON.parse(e.request_json);
        if (request_json.lead_id) {
          this.isFromPefai = true;
          this.origenUuidToPefai = e.uuid;
          return true;
        }
      });

      //Segundo checo si ya se envio a pefai y por lo tanto si tiene un lead_id de pefai en el registro del lead
      var pefaiResponse = {};
      if (typeof this.lead.pefaiResponse != "undefined") {
        pefaiResponse = JSON.parse(this.lead.pefaiResponse);
        if (pefaiResponse.lead_id) this.wasSeeditToPefai = true;
      }else{
        this.wasSeeditToPefai = false
      }

      //Tercero checo si alguna de las campanias del lead tiene pefai = 1 por lo tanto deba mandarse a pefai y no sea mandado anteriormente
      //Si el lead tiene lead_id pero el request json no pero tiene que mandarse el UUID Origen de la campaña que se tiene que mandar a pefai
      const campaings = await getCampaingsList({});
      this.infoCards.campaings.sort(
        (a, b) => new Date(b.fecha_lead) - new Date(a.fecha_lead)
      );
      this.infoCards.campaings.some((e) => {
        const foundCampaing = campaings.campanias.find((campaing) => {
          return campaing.medio === e.medio;
        });
        if (foundCampaing && foundCampaing.pefai == 1) {
          if(!this.wasSeeditToPefai)
            this.needBeCreateInPefai = true;
          this.origenUuidToPefai = e.uuid;
          this.origenIdToPefai = e.id;
          return true;
        }
      });
    },
    leadCreadoPefai() {
      this.needBeCreateInPefai = false;
      this.wasSeeditToPefai = true;
    },
    leadIncomplete() {
      this.$toast.warning(
        "Se deben completar los datos del lead antes de crear una poliza/cotizacion",
        {
          position: "top-right",
          timeout: 5000,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
          rtl: false,
        }
      );
    },
    polizaSuccess() {
      this.openCreatePolizaModal = false;
      this.getLead();
    },
  },

  created() {
    this.llamadaManual = this.$route.query.llamadaManual
        ? this.$route.query.llamadaManual === "true"
          ? true
          : false
        : false;
    this.getLead();
    this.getLlamadas();
    this.origenLeadUuid = this.$route.query.origenLeadUuid ?? false;
    if (this.origenLeadUuid) {
      this.getOrigenLead();
    }
  },
};
</script>
