<template>
  <div>
    <!-- <CommondLoading
      v-if="loadingModal"
      :loadingImage="dataLoading.inter"
      :show="loadingModal"
      loadingText="Cargando info..."
    /> -->
    <iframe
      @load="handleIframe"
      :src="url"
      style="width: 100%; min-height: 1100px;"
      frameborder="0"
    ></iframe>
  </div>
</template>

<script>
import dataLoading from "@/helpers/data/data-loading-gif.json";

export default {
  data() {
    return {
      loadingModal: true,
      dataLoading: dataLoading,
    };
  },
  components: {
    // CommondLoading: () =>
    //   import("@/components/commonComponents/CommondLoading.vue"),
  },
  props: {
    clienteInfo: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    url() {
      return this.clienteInfo.correo ? `${process.env.VUE_APP_URL_IFRAME_POSTVENTA}?email=${this.clienteInfo.correo}&agent=${localStorage.agenteMail}&clientId=${this.clienteInfo.id ?? ''}` : '';
    },
  },
  watch: {
    
  },
  methods: {
    handleIframe() {
      this.loadingModal = false;
    },
  },
};
</script>
