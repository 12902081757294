<template>
  <v-row>
    <v-col cols="12" sm="6" md="4" lg="4" xl="4">
      <span class="text-header-data">
        Estatus
      </span>
      <br />
      <v-btn rounded dark style="background-color: #f1c21b;" :elevation="0">
        <span>
          {{ getStatus(polizaData.status) ? getStatus(polizaData.status) : "Sin llenar" }}
        </span>
      </v-btn>
    </v-col>
    <v-col cols="6" sm="4" md="4" lg="4" xl="4">
      <span class="text-header-data ">
        No. Póliza
      </span>
      <br />
      <span class="text-data-details">
        {{ polizaData.poliza ? polizaData.poliza : "Sin llenar" }}
      </span>
    </v-col>
    <v-col cols="6" sm="4" md="4" lg="4" xl="4">
      <span class="text-header-data ">
        Poliza ID Interna
      </span>
      <br />
      <span class="text-data-details">
        {{ polizaData.id ? polizaData.id : "Sin llenar" }}
      </span>
    </v-col>
    <v-col cols="6" sm="4" md="4" lg="4" xl="4">
      <span class="text-header-data ">
        No. Cotización / Lead ID
      </span>
      <br />
      <span class="text-data-details">
        {{ polizaData.id ? polizaData.id : "Sin llenar" }}
      </span>
    </v-col>
    <v-col cols="6" sm="4" md="4" lg="4" xl="4">
      <span class="text-header-data ">
        Tipo de Póliza
      </span>
      <br />
      <span class="text-data-details">
        {{ polizaData.tipoPoliza ? polizaData.tipoPoliza : "Sin llenar" }}
      </span>
    </v-col>
    <v-col cols="6" sm="4" md="4" lg="4" xl="4">
      <span class="text-header-data ">
        Agente
      </span>
      <br />
      <span class="text-data-details">
        {{ polizaData.nombreAgente ? polizaData.nombreAgente : "Sin llenar" }}
      </span>
    </v-col>
    <v-col cols="6" sm="4" md="4" lg="4" xl="4">
      <span class="text-header-data ">
        Ramo
      </span>
      <br />
      <span class="text-data-details">
        {{ requestJson ? requestJson.categoriaSelected : "Sin llenar" }}
      </span>
    </v-col>
    <v-col cols="6" sm="4" md="4" lg="4" xl="4">
      <span class="text-header-data ">
        Productos
      </span>
      <br />
      <span class="text-data-details">
        {{ polizaData.nombreProducto ? polizaData.nombreProducto : "Sin llenar" }}
      </span>
    </v-col>
    <v-col cols="6" sm="4" md="4" lg="4" xl="4">
      <span class="text-header-data ">
        Campaña
      </span>
      <br />
      <span class="text-data-details">
        {{ polizaData.campania ? polizaData.campania : "Sin llenar" }}
      </span>
    </v-col>
    <v-col cols="6" sm="4" md="4" lg="4" xl="4">
      <span class="text-header-data ">
        Periodicidad de pago
      </span>
      <br />
      <span class="text-data-details">
        {{ polizaData.pagoId ? getPeridicidad(polizaData.pagoId) : "Sin llenar" }}
      </span>
    </v-col>
    <v-col cols="6" sm="4" md="4" lg="4" xl="4">
      <span class="text-header-data ">
        Inicio de Vigencia
      </span>
      <br />
      <span class="text-data-details">
        {{
          polizaData.inicio ? getFormattDate(polizaData.inicio) : "Sin llenar"
        }}
      </span>
    </v-col>
    <v-col cols="6" sm="4" md="4" lg="4" xl="4">
      <span class="text-header-data ">
        Fin de Vigencia
      </span>
      <br />
      <span class="text-data-details">
        {{ polizaData.fin ? getFormattDate(polizaData.fin) : "Sin llenar" }}
      </span>
    </v-col>
    <v-col cols="12" sm="12" md="12" lg="12" xl="12" style="text-align: end;">
      <v-btn
        rounded
        dark
        @click="goToPolizaDetails()"
        elevation="0"
        class="common-botton"
      >
        <span>
          Ver detalle
        </span>
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
import moment from "moment";

export default {
  components: {},
  props: {
    polizaData: Array,
  },
  data() {
    return {
      switch_data: 1,
    };
  },

  computed: {
    requestJson() {
      return this.polizaData ? JSON.parse(this.polizaData.requestJson) : null;
    },
  },

  methods: {
    changeComponent(component) {
      this.switch_data = component;
    },
    getFormattDate(date) {
      return moment(date, "YYYY-MM-DD HH:mm:ss").format("DD/MM/YYYY");
    },
    getStatus(val) {
      switch (val) {
        case 0:
          return "Nueva";
        case 1:
          return "Emitida Pendente de Pago";
        case 2:
          return "Pagada Parcial";
        case 3:
          return "Pagada Total";
        case 4:
          return "Renovada";
        case 5:
          return "Finalizada";
        case 8:
          return "Duplicada";
        default:
          return "Cancelada";
      }
    },

    getPeridicidad(val) {
      switch (val) {
        case 1:
          return "Anual";
        case 2:
          return "Semestral";
        case 3:
          return "Trimestral";
        default:
          return "Mensual";
      }
    },

    goToPolizaDetails() {
      this.$router.push(`/detalles-emision/${this.polizaData.id}`);
    },
  },
};
</script>
